"use strict";

var _slicedToArray = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20241209093412/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = require("react");

function useDebounce(value, delay) {
  var _react_1$useState = react_1.useState(value),
      _react_1$useState2 = _slicedToArray(_react_1$useState, 2),
      debouncedValue = _react_1$useState2[0],
      setDebouncedValue = _react_1$useState2[1];

  react_1.useEffect(function () {
    var handler = setTimeout(function () {
      setDebouncedValue(value);
    }, delay);
    return function () {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
}

exports.useDebounce = useDebounce;

exports.usePrevious = function (value) {
  var ref = react_1.useRef();
  react_1.useEffect(function () {
    ref.current = value;
  });
  return ref.current;
};

function useHover() {
  var _react_1$useState3 = react_1.useState(false),
      _react_1$useState4 = _slicedToArray(_react_1$useState3, 2),
      value = _react_1$useState4[0],
      setValue = _react_1$useState4[1];

  var ref = react_1.useRef(null);

  var handleMouseOver = function handleMouseOver() {
    return setValue(true);
  };

  var handleMouseOut = function handleMouseOut() {
    return setValue(false);
  };

  react_1.useEffect(function () {
    var node = ref.current;

    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return function () {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }

    return function () {};
  }, [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}

exports.useHover = useHover;

function useOnClickOutside(ref, handler) {
  react_1.useEffect(function () {
    var listener = function listener(event) {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return function () {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, // Add ref and handler to effect dependencies
  // It's worth noting that because passed in handler is a new ...
  // ... function on every render that will cause this effect ...
  // ... callback/cleanup to run every render. It's not a big deal ...
  // ... but to optimize you can wrap handler in useCallback before ...
  // ... passing it into this hook.
  [ref, handler]);
}

exports.useOnClickOutside = useOnClickOutside;

function useOnKeyPress(targetKey, deps, onKeyDown) {
  var onKeyUp = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {};

  function downHandler(_ref) {
    var key = _ref.key;

    if (key === targetKey) {
      onKeyDown();
    }
  }

  var upHandler = function upHandler(_ref2) {
    var key = _ref2.key;

    if (key === targetKey) {
      onKeyUp();
    }
  }; // Add event listeners


  react_1.useEffect(function () {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler); // Remove event listeners on cleanup

    return function () {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, deps);
}

exports.useOnKeyPress = useOnKeyPress;