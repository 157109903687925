"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20241209093412/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _toConsumableArray = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20241209093412/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.generateFiltersFromSources = function (sources) {
  return function (filters, props) {
    var resultFilters = [];
    return _toConsumableArray(sources.reduce(function (acc, filterSource) {
      var getList = filterSource.getList,
          findPreviousFilter = filterSource.findPreviousFilter,
          getLabel = filterSource.getLabel,
          getCount = filterSource.getCount,
          filterCategory = filterSource.filterCategory;

      if (!getList) {
        // Cas du filtre textuel
        return [].concat(_toConsumableArray(acc), [{
          filterCategory: filterCategory,
          filterContent: (filters.find(function (filter) {
            return filter.filterCategory === filterCategory;
          }) || {
            filterContent: ''
          }).filterContent
        }]);
      }

      var list = getList(props);
      return [].concat(_toConsumableArray(acc), _toConsumableArray(list.map(function (source) {
        var previousFilter = filters.find(findPreviousFilter ? findPreviousFilter(source) : function () {
          return true;
        });
        return _objectSpread({
          label: getLabel ? getLabel(source) : '',
          isSelected: previousFilter ? previousFilter.isSelected : false,
          count: getCount ? getCount(source, props) : 0,
          filterCategory: filterCategory
        }, source);
      })));
    }, resultFilters));
  };
};

function getCountForFilter(_ref) {
  var filter = _ref.filter,
      filterResources = _ref.filterResources,
      items = _ref.items,
      getItemMatcher = _ref.getItemMatcher;
  return items.filter(getItemMatcher(filter, filterResources)).length;
}

exports.getCountForFilter = getCountForFilter;

function getFiltersFromCategorySelection(selectedFiltersByCategory, filters) {
  var selectFilters = filters.filter(function (filter) {
    return filter.hasOwnProperty('id');
  });
  var updatedSelectFilters = getUpdatedSelectFilters(selectedFiltersByCategory, selectFilters);
  var textFilters = filters.filter(function (filter) {
    return !filter.hasOwnProperty('id');
  });
  var updatedTextFilters = getUpdatedTextFilters(selectedFiltersByCategory, textFilters);
  return [].concat(_toConsumableArray(updatedSelectFilters), _toConsumableArray(updatedTextFilters));
}

exports.getFiltersFromCategorySelection = getFiltersFromCategorySelection;

function mapSelectionId(newFilterSelection) {
  return _toConsumableArray(newFilterSelection.map(function (filterSelection) {
    return filterSelection.id;
  })).filter(function (id) {
    return !!id;
  });
}

function getUpdatedSelectFilters(selectedFiltersByCategory, filters) {
  var tmp = [];
  var selectedFiltersValuesFromGivenCategories = tmp.concat.apply(tmp, _toConsumableArray(Object.values(selectedFiltersByCategory).map(mapSelectionId)));
  var selectedFiltersFromGivenCategoriesWithIds = filters.filter(function (filter) {
    return selectedFiltersValuesFromGivenCategories.includes(filter.id);
  }); //Hack qui permet de récupérer le filtre sans lamination qui n'a pas d'id

  var filterWithoutLamination = selectedFiltersByCategory.WORKSHOP_FILTER_CATEGORY_LAMINATION && selectedFiltersByCategory.WORKSHOP_FILTER_CATEGORY_LAMINATION.find(function (lamination) {
    return !lamination.id;
  });
  var selectedFiltersFromGivenCategories = filterWithoutLamination !== undefined ? [].concat(_toConsumableArray(selectedFiltersFromGivenCategoriesWithIds), [filterWithoutLamination]) : selectedFiltersFromGivenCategoriesWithIds;
  var tmp2 = [];
  var unselectedFiltersFromGivenCategories = tmp2.concat.apply(tmp2, _toConsumableArray(Object.keys(selectedFiltersByCategory).map(function (category) {
    return filters.filter(function (filter) {
      return filter.filterCategory === category && !selectedFiltersValuesFromGivenCategories.includes(filter.id);
    });
  })));
  var untouchedFilters = filters.filter(function (filter) {
    return !selectedFiltersFromGivenCategories.find(function (selectedFilter) {
      return selectedFilter.id === filter.id;
    }) && !unselectedFiltersFromGivenCategories.find(function (unselectedFilter) {
      return unselectedFilter.id === filter.id;
    });
  });
  return [].concat(_toConsumableArray(untouchedFilters), _toConsumableArray(selectedFiltersFromGivenCategories.map(function (filter) {
    return _objectSpread({}, filter, {
      isSelected: true
    });
  })), _toConsumableArray(unselectedFiltersFromGivenCategories.map(function (filter) {
    return _objectSpread({}, filter, {
      isSelected: false
    });
  })));
}

function getUpdatedTextFilters(selectedFiltersByCategory, filters) {
  var categories = Object.keys(selectedFiltersByCategory);
  var selectedFiltersFromCategories = filters.filter(function (filter) {
    return categories.includes(filter.filterCategory);
  }).map(function (filter) {
    return _objectSpread({}, filter, {
      filterContent: selectedFiltersByCategory[filter.filterCategory][0] ? selectedFiltersByCategory[filter.filterCategory][0].filterContent : ''
    });
  });
  var unselectedFiltersFromCategories = filters.filter(function (filter) {
    return !categories.includes(filter.filterCategory);
  }).map(function (filter) {
    return _objectSpread({}, filter, {
      filterContent: ''
    });
  });
  return [].concat(_toConsumableArray(selectedFiltersFromCategories), _toConsumableArray(unselectedFiltersFromCategories));
}